<template>
  <div class="animated fadeIn container">
    <h1>3사 수신여부를 확인할 수 있습니다</h1>
    <h2 class="mt-2 color-skyblue">{{ availableSendCount }}건 발송가능</h2>
    <p class="mt-4" style="color:#666;margin-bottom:2rem">
      * 3사 테스트 결과는 새로고침을 하지 않으셔도 실시간 업데이트됩니다. 자주 새로고침시 디도스로 오인하여 1분간 접속 차단될 수 있습니다.
      <br />
      * 성공 태그는 보통 2~3초 이내에 떠야 합니다. 대기가 10초 이상 떠 있다면, 문구를 교체해 주세요.
    </p>
    <div class="row mt-4">
      <div class="col-4">
        <div class="phone-wrap">
          <textarea class="inner-text" id="message" name="body" rows="4" v-model="message" placeholder="메시지를 입력해주세요"
            v-on:input="updateMessage" required></textarea>
          <button id="emoji-btn" @click="clickEmojiBtn" v-b-tooltip.hover title="이모티콘 추가"></button>
          <button id="img-btn" @click="clickImgBtn" v-b-tooltip.hover title="이미지 링크 발송"></button>
          <button id="url-btn" @click="clickUrlBtn" v-b-tooltip.hover title="짧은 도메인 변환"></button>
          <button id="ccc-btn" @click="clickCccBtn" v-b-tooltip.hover title="한글 도메인 변환"></button>
          <span class="color-flatwhite inner-bytes">{{ contentBytes }}/70</span>
        </div>
        <div id="emoji-picker" v-show="showEmojiPicker"></div>
        <b-btn ref="sendBtn" variant="primary" class="send-btn" @click="createSmsRequest">
          <span>3사 테스트 발송</span>
        </b-btn>
      </div>
      <div class="col-8" style="margin-bottom: 150px">
        <table class="border-table request-table mt-20" v-show="list.length > 0">
          <thead>
            <tr>
              <td v-if="$store.state.user && $store.state.user.level == 9" class="username">
                이름
              </td>
              <td class="date">발송시각</td>
              <td class="message">내용</td>
              <td class="message-list">상태</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in  list " :key="index">
              <td v-if="$store.state.user && $store.state.user.level == 9" class="username">
                <span @click="goUserAdmin(item.user)">
                  {{ item.user.username }}
                </span>
              </td>
              <td class="date">{{ item.createdAt | dateFormatWithNewLine }}</td>
              <td class="message">
                <span v-html="domPurify(item.message)" @click="pasteMessage(item.message)" class="copy-message"
                  v-b-tooltip.hover title="클릭해서 다시 사용✨"></span>
              </td>
              <td class="company-list">
                <div>
                  SKT
                  <span class="status-label success" v-if="item.skt == 1">성공</span>
                  <span class="status-label warning" v-else>대기</span>
                </div>
                <div>
                  KT
                  <span class="status-label success" v-if="item.kt == 1">성공</span>
                  <span class="status-label warning" v-else>대기</span>
                </div>
                <div>
                  LG
                  <span class="status-label success" v-if="item.lg == 1">성공</span>
                  <span class="status-label warning" v-else>대기</span>
                  <span v-if="$store.state.user && $store.state.user.level == 9" class="ml-3"
                    style="cursor: pointer;font-size: 20px;" @click="resend(item.id)">⟳</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="border-container" v-show="isInit && list.length === 0">
          <div class="empty-view">
            3사 테스트 발송 내역이 없습니다
          </div>
        </div>
        <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
        <b-pagination align="center" v-if="this.user && this.user.level == 9" :total-rows="totalCount"
          v-model="currentPage" :per-page="10" :limit="10" class="mt-4 " @change="changePage"></b-pagination>
        <b-btn variant="danger" class="float-right clear-btn" @click="deleteAllRequests" :disabled="isLoading">
          <span v-if="!isLoading">발송내역 삭제</span>
          <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
        </b-btn>
      </div>
    </div>
    <b-modal ref="sendCompleteModal" ok-only ok-title="확인" centered>메시지 전송 완료</b-modal>
    <ImgUploadPopup v-if="showImgPopup" :key="popupKey" @use-link="useLink" @close="closeImgUploadPopup" />
    <UrlPopup v-if="showUrlPopup" :key="popupKey" @use-link="useLink" @close="closeUrlPopup" />
    <CccPopup v-if="showCccPopup" :key="popupKey" @use-link="useLink" @close="closeCccPopup" />
  </div>
</template>
<script>
import SmsTestService from '@/services/SmsTestService'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import { mapGetters } from 'vuex'
import ImgUploadPopup from '@/views/components/ImgUploadPopup'
import UrlPopup from '@/views/components/UrlPopup'
import CccPopup from '@/views/components/CccPopup'

export default {
  name: 'SmsSendTest',
  components: {
    PulseLoader,
    ImgUploadPopup,
    UrlPopup,
    CccPopup
  },
  data() {
    return {
      isInit: false,
      availableSendCount: 0,
      message: '',
      isLoading: false,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      totalCount: Number.MAX_SAFE_INTEGER,
      currentPage: 1,
      userId: null,
      list: [],
      timer: null,
      showEmojiPicker: false,
      showImgPopup: false,
      showUrlPopup: false,
      showCccPopup: false,
      popupKey: 1
    }
  },
  created() {
    this.currentPage = parseInt(this.$route.query.page) || 1

    // setTimeout(function () {
    //   if ('/smsSendTest' === location.pathname) {
    //     alert('알림: 현재 서비스 전체적으로 딜레이가 걸리고 있습니다. 금일 페이스북측이 한국으로 문자를 폭탄 수준으로 사용하고 있어서 글로벌 문자망 전체적으로 딜레이가 심하게 걸리고 있습니다. 아주 이례적인 케이스이며, 느리더라도 정상적으로 작동은 되오니 불편하시더라도 조금 기다려주시면 감사하겠습니다. 이용에 불편을 드려 죄송합니다.')
    //   }
    // }, 1000);
  },
  mounted() {
    // alert('현재 통신사 장애가 있습니다. 최대한 빠르게 복구하겠습니다. 불편을 드려 죄송합니다.')
    document.body.classList.remove('bg-white')
    this.getUserInfo()
    this.initEmojiPicker();

    const t = this
    this.userId = this.$route.query.userId
    t.getRequests()
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  methods: {
    initEmojiPicker() {
      try {
        const t = this
        const pickerOptions = {
          set: 'apple',
          i18n: {
            "search": "검색",
            "search_no_results_1": "어머나!",
            "search_no_results_2": "결과를 찾을 수 없어요",
            "pick": "이모지 선택하기",
            "add_custom": "이모지 추가하기",
            "categories": {
              "activity": "활동",
              "custom": "커스텀",
              "flags": "깃발",
              "foods": "음식 및 음료",
              "frequent": "자주 사용하는 항목",
              "nature": "동물 및 자연",
              "objects": "사물",
              "people": "스마일리 및 사람",
              "places": "여행 및 장소",
              "search": "검색 결과",
              "symbols": "기호"
            },
            "skins": {
              "1": "표준",
              "2": "밝은 피부색",
              "3": "약간 밝은 피부색",
              "4": "중간 피부색",
              "5": "약간 어두운 피부색",
              "6": "어두운 피부색",
              "choose": "기준 피부색 선택하기"
            }
          },
          onEmojiSelect: function (emoji) {
            t.message = t.message + emoji.native
          },
          onClickOutside: function () {
            t.showEmojiPicker = false
          }
        }
        const picker = new window.EmojiMart.Picker(pickerOptions)
        document.getElementById('emoji-picker').appendChild(picker);
      }
      catch (e) {
        // do nothing
      }
    },
    clickEmojiBtn() {
      const t = this
      setTimeout(() => {
        t.showEmojiPicker = true
      }, 50)
    },
    clickImgBtn() {
      this.showImgPopup = true
      this.popupKey += 1
      document.body.style.overflow = 'hidden';
    },
    closeImgUploadPopup() {
      this.showImgPopup = false
      document.body.style.overflow = 'auto';
    },
    clickUrlBtn() {
      this.showUrlPopup = true
      this.popupKey += 1
      document.body.style.overflow = 'hidden';
    },
    clickCccBtn() {
      this.showCccPopup = true
      this.popupKey += 1
      document.body.style.overflow = 'hidden';
    },
    closeUrlPopup() {
      this.showUrlPopup = false
      document.body.style.overflow = 'auto';
    },
    closeCccPopup() {
      this.showCccPopup = false
      document.body.style.overflow = 'auto';
    },
    createSmsRequest() {
      if (this.contentBytes <= 0) {
        alert('메시지를 입력해주세요.')
        return
      } else if (this.contentBytes > 70 && this.user.level < 9) {
        alert('메시지는 70자까지만 가능합니다')
        return
      }

      if (this.isLoading) {
        return
      }
      this.isLoading = true

      const t = this
      SmsTestService.createSmsRequest({ message: this.message })
        .then(function (response) {
          if (response.status !== 200) {
            return
          }
          t.getUserInfo()
          t.getRequests()
          t.$refs.sendCompleteModal.show()
          setTimeout(function () {
            t.isLoading = false
          }, 200)
        })
        .catch(function () {
          t.isLoading = false
        })
    },
    async getRequests() {
      const t = this
      try {
        clearTimeout(t.timer);
        const response = await SmsTestService.getRequests({ page: t.currentPage, userId: t.userId })
        // t.isLoading = false
        if (response.status !== 200) {
          return
        }
        const { list = [], totalCount = 0 } = response.data
        t.list = list
        t.totalCount = totalCount
        t.isInit = true
      }
      catch (e) {
        // do nothing
      }
      finally {
        t.timer = setTimeout(() => {
          t.getRequests()
        }, 5000)
      }
      // t.isLoading = true
    },
    updateMessage(e) {
      this.message = e.target.value
    },
    async deleteAllRequests() {
      if (!confirm('삭제하시겠습니까?')) {
        return
      }
      const response = await SmsTestService.delete()
      if (response.status == 200) {
        alert('성공')
        this.getRequests()
      }
    },
    async resend(requestId) {
      if (!this.user || this.user.level < 9) {
        return
      }

      // if (!confirm('재발송하시겠습니까?')) {
      //   return
      // }

      await SmsTestService.resend({ id: requestId });
    },
    changePage(page) {
      var query = {
        page: page,
      }

      if (this.userId) {
        query.userId = this.userId
      }

      this.$router.push({
        path: '/smsSendTest',
        query: query,
      })
    },
    pasteMessage(message) {
      this.message = message
    },
    goUserAdmin(user) {
      this.$router.push('/admin/users/' + user.id)
    },
    useLink(link) {
      let message = this.message;
      if (message.trim().length > 0) {
        message += '\n';
      }
      message += link

      this.message = message;
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    contentBytes: function () {
      var message = this.message.replace(/(\r\n|\r|\n)/g, ' ')
      return message.length
    },
  },
  watch: {
    user() {
      this.availableSendCount = parseInt(this.user.money / this.user.smsFee)
    },
    list() { },
    isLoading() {
      this.$refs.sendBtn.disabled = this.isLoading
    },
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getRequests()
    },
    '$route.query.userId'() {
      this.userId = this.$route.query.userId
      this.getRequests()
    },
  },
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 50px;
  font-weight: 300;
}

.border-container {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.empty-view {
  line-height: 300px;
  text-align: center;
}

#sms-send-loader {
  display: block;
  margin: 20px auto;
}

.phone-wrap {
  position: relative;
  width: 256px;
  height: 523px;
  background: url('/img/phone_test.webp') center no-repeat;
}

.phone-wrap .inner-text {
  position: absolute;
  top: 65px;
  left: 18px;
  width: 220px;
  bottom: 65px;
  padding: 20px 15px;
  background: transparent;
  color: white;
  line-height: 20px;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  border: none;
  letter-spacing: 1;
}

.phone-wrap .inner-text::placeholder {
  color: #999;
  font-weight: 500;
}

.phone-wrap .inner-bytes {
  position: absolute;
  right: 33px;
  bottom: 73px;
}

#emoji-btn {
  position: absolute;
  left: 28px;
  bottom: 75px;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  font-size: 24px;
  background: url(/img/smsSend/icon/send_emoji.png) center no-repeat;
  background-size: contain;
  overflow: hidden;
  opacity: 0.4;
  filter: brightness(0) invert(1);
}

#img-btn {
  position: absolute;
  left: 62px;
  bottom: 75px;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  font-size: 24px;
  background: url(/img/smsSend/icon/send_img.png) center no-repeat;
  background-size: contain;
  opacity: 0.4;
  filter: brightness(0) invert(1);
}

#url-btn {
  position: absolute;
  left: 96px;
  bottom: 75px;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  font-size: 24px;
  background: url(/img/smsSend/icon/send_url.png) center no-repeat;
  background-size: contain;
  opacity: 0.4;
  filter: brightness(0) invert(1);
}

#ccc-btn {
  position: absolute;
  left: 130px;
  bottom: 75px;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  font-size: 24px;
  background: url(/img/smsSend/icon/send_ccc.png) center no-repeat;
  background-size: contain;
  opacity: 0.4;
  filter: brightness(0) invert(1);
}

#emoji-picker {
  margin-top: -72px;
  margin-left: 20px;
}

.send-btn {
  display: block;
  margin-top: 30px;
  margin-left: 60px;
  height: 40px;
  border-radius: 0.25rem;
  padding: 0 20px;
}

.clear-btn {
  margin-top: 20px;
  float: right;
  height: 40px;
  border-radius: 0.25rem;
  padding: 0 20px;
}

#server-status-sub-msg {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;
  white-space: nowrap;
  letter-spacing: -0.2px;
}

.request-table {
  margin-top: -20px;
  border-bottom: 1px solid #000;

  tbody {
    >tr {
      border-top: 1px solid #d0d0d0;
    }

    >td {
      min-height: 70px;
    }
  }

  thead td {
    text-align: center;
  }

  .username {
    min-width: 80px;
  }

  .date {
    min-width: 140px;
    line-height: 20px;
    white-space: pre;
    text-align: center;
  }

  .message {
    width: 100%;
    word-break: break-all;
    line-height: 20px;
  }

  .copy-message {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .company-list {
    min-width: 280px;
    line-height: 20px;
    text-align: center;

    >div {
      display: inline-block;
      padding: 0 8px;
    }

    .status-label {
      padding: 3px 10px;
      font-size: 10px;
      border-radius: 2px;
      color: white;

      &.success {
        background-color: #6cba7b;
      }

      &.warning {
        background-color: #f6c244;
      }

      &.primary {
        background-color: #009bfa;
      }
    }
  }
}
</style>
